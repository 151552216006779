<template>
    <div class="content">
        <h3 class="title">{{ content.title }}</h3>
        <div class="date">{{ content.date }}</div>
        <div class="excerpt">{{ content.excerpt }}</div>
        <router-link class="link" :to="content.url">{{ $t('readMore') }}</router-link>
    </div>
</template>

<script>
    export default {
        name: 'NewsArticleContent',

        props: [
            'content'
        ]
    }
</script>

<style lang="scss" scoped>
    .content {
        display: flex;
        flex-direction: column;
        width: calc(50% - 260px);
        padding: 78px 130px;
    
        @media only screen and (max-width: 1024px) {
            padding: 42px 40px;
            width: calc(100% - 80px);
        }
    
        .title {
            font-family: 'ArdelaEdgeX02-SemiBold';
            font-size: 40px;
            text-align: start;
            line-height: 1.3em;
            margin: 0 0 10px 0;
    
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    
        .date {
            text-align: start;
            margin-bottom: 24px;
            font-weight: bold;
            font-size: 16px;
        }
    
        .excerpt {
            text-align: start;
            margin-bottom: 24px;
    
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    
        .link {
            border: 2px solid $timberGreen;
            color: $timberGreen;
            padding: 10px 30px;
            align-self: flex-start;
            cursor: pointer;
    
            a {
                color: inherit;
            }
        }
    }

    h3.title {
        color: $timberGreen;
        text-align: start;

        @media only screen and (max-width: 800px) {
            font-size: 32px !important;
            -webkit-line-clamp: unset !important;
            -webkit-box-orient: unset !important;
        }
    }
</style>
