<template>
    <div class="alternating-list">
        <div v-for="(item, index) in list" :key="item.id" class="item" :class="itemClass(item)">
            <router-link v-if="item.image" :name="item.title" class="img" :to="item.url">
                <div class="shadow"></div>
                <picture>
                    <template v-for="[format, set] of Object.entries(srcsets[index])">
                        <source v-if="format !== originalFormats[index]" :srcset="set" sizes="(max-width: 1100px) 60vw, 50vw" :type="'image/' + format.slice(1)">
                    </template>
                    <img loading="lazy" decoding="async" :srcset="srcsets[index][originalFormats[index]]" :type="'image/' + originalFormats[index].slice(1)" sizes="(max-width: 1100px) 100vw, 60vw" :alt="item.image.alternativeText">
                </picture>
            </router-link>
            <EventContent :content="item.subComponent" v-if="contentType == 'events'"/>
            <NewsArticleContent :content="item.subComponent" v-if="contentType == 'newsArticles'"/>
        </div>
    </div>
</template>

<script>
    import EventContent from '@/components/AlternatingList/EventContent'
    import NewsArticleContent from '@/components/AlternatingList/NewsArticleContent'

    export default {
        name: 'AlternatingList',

        props: [
            'list',
            'contentType'
        ],

        components: {
            EventContent,
            NewsArticleContent
        },

        computed: {
            srcsets(){
                let srcsets = []
                for (const item of this.list) {
                    srcsets.push(this.getImageSrcsets(item.image))
                }
                return srcsets
            },
            
            hasWebp(){
                let hasWebps = []
                for (const srcset of this.srcsets) {
                    hasWebps.push(srcset['.webp'] ? true : false)
                }
                return hasWebps
            },

            originalFormats(){
                let originalFormats = []
                for (const srcset of this.srcsets) {
                    for (const[format, _] of Object.entries(srcset)) {
                        if (format !== '.webp') {
                            originalFormats.push(format)
                        }
                    }
                }
                
                return originalFormats
            },
        },

        methods: {
            itemClass(item) {
                if(!item.image){
                    return {
                        full: true
                    }
                } else {
                    if(item.index % 2 == 0){
                        return {
                            even: true,
                        }
                    } else {
                        return {
                            odd: true
                        }
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .alternating-list {
        color: $timberGreen;

        .item {
            width: 100%;
            height: 600px;
            display: flex;
            background-color: $lightGrey;
            max-width: unset;
            padding: unset;

            @media only screen and (max-width: 1024px) {
                flex-direction: column;
                height: unset;
            }


            .img {
                width: 50%;
                position: relative;

                &:hover {
                    opacity: 1;

                    .shadow {
                        opacity: 0.25;
                    }
                }

                img {
                    object-fit: cover;
                    object-position: 50% 50%;
                    width: 100%;
                    height: 100%;
                }

                .shadow {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: black;
                    opacity: 0;
                    transition: 0.25s;
                }

                @media only screen and (max-width: 1024px) {
                    width: 100%;
                }
            }

            &.even {
                .img {
                    order: 0;
                }
                .content {
                    order: 1;
                }
            }

            &.odd {
                .img {
                    order: 1;

                    @media only screen and (max-width: 1024px) {
                        order: 0;
                    }
                }
                .content {
                    order: 0;

                    @media only screen and (max-width: 1024px) {
                        order: 1;
                    }
                }
            }

            &.full {
                background-color: white;

                .content {
                    width: 100%;

                    @media only screen and (max-width: 1024px) {
                        width: calc(100% - 80px);
                    }
                }
            }
        }
    }
</style>
