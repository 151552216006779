<template>
    <div class="content">
        <h3 class="title">{{ content.title }}</h3>
        <div class="details">
            <div class="details-item" v-for="item in details">
                <span class="key">{{ item.key }}: </span>
                <span class="value">{{ item.value }}</span>
            </div>
        </div>
        <div class="excerpt">{{ removeMd(content.description) }}</div>
        <router-link class="link" :to="content.url">{{ $t('visitEvent') }}</router-link>
    </div>
</template>

<script>
    import { dateFormat, timeFormat } from '@/helper'

    export default {
        name: 'EventContent',

        props: [
            'content'
        ],

        computed: {
            details() {
                return [
                    {
                        key: this.$t('date'),
                        value: dateFormat(this.content.date)
                    },{
                        key: this.$t('time'),
                        value: timeFormat(this.content.startTime) + '–' + timeFormat(this.content.endTime) + ' ' + this.$t('oclock')
                    },{
                        key: this.$t('place'),
                        value: this.content.place
                    },{
                        key: this.$t('organizer'),
                        value: this.content.organizer
                    },{
                        key: this.$t('format'),
                        value: this.content.formatNameTranslated
                    },{
                        key: this.$t('content'),
                        value: this.content.content
                    },{
                        key: this.$t('targetGroup'),
                        value: this.content.targetGroupTranslated
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .content {
        display: flex;
        flex-direction: column;
        width: calc(50% - 200px);
        padding: 78px 100px;

        @media only screen and (max-width: 1024px) {
            padding: 42px 40px;
            width: calc(100% - 80px);
        }

        .title {
            font-family: 'ArdelaEdgeX02-SemiBold';
            font-size: 40px;
            text-align: start;
            line-height: 1.3em;
            margin: 0 0 10px 0;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        .details {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
            margin-top: 20px;

            .details-item {
                margin-right: 4px;

                .value {
                    font-weight: 800;
                }

                &:after {
                    content: " | ";
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .excerpt {
            text-align: start;
            margin-bottom: 40px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        .link {
            border: 2px solid $timberGreen;
            color: $timberGreen;
            padding: 10px 30px;
            align-self: flex-start;
            cursor: pointer;
    
            a {
                color: inherit;
            }
        }
    }

    h3.title {
        color: $timberGreen;

        @media only screen and (max-width: 800px) {
            font-size: 32px !important;
            -webkit-line-clamp: unset !important;
            -webkit-box-orient: unset !important;
        }
    }
</style>
